import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const Link = (props) => {
  return (
    <AniLink hex="#229ebd66" duration={0.5} {...props}>
      {props.children} 
    </AniLink>
  );
}; 

// cover to={to} duration={0.8}
// bg="
// url(https://cdn.shopify.com/s/files/1/0278/3612/1223/files/logoOnly_400x.png?v=1611385194)
// center / 400px   /* position / size */
// no-repeat        /* repeat */
// fixed            /* attachment */
// padding-box      /* origin */
// content-box      /* clip */
// #229ebd66            /* color */
// "
